<template lang="pug">
  v-container(fluid)
    v-alert.adock-show-linebreaks(
      v-if="errorMessage"
      type="error"
      outlined
      text
    ) {{ errorMessage }}
    v-row
      v-col(cols="12" md="3")
        v-row(
          v-for="(label, action) in actions"
          :key="action"
        )
          v-btn.ma-4(@click="postSelfTest(action)") {{ label }}
      v-col(cols="12" md="9")
        v-card.adock-card
          v-card-text {{ output }}
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      actions: [],
      errorMessage: "",
      output: "",
    };
  },

  async created() {
    const response = await api.get(api.selfTestsUrl);
    if (response.status === 200) {
      this.actions = response.data.actions;
    }
  },

  methods: {
    async postSelfTest(name) {
      this.errorMessage = "";
      this.output = "";
      const response = await api.post(api.selfTestsUrl, {
        [name]: true,
      });
      if (response.status === 200) {
        this.output = response.data.output;
      } else {
        this.errorMessage = response.data.message;
      }
    },
  },
};
</script>
