import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.errorMessage)?_c(VAlert,{staticClass:"adock-show-linebreaks",attrs:{"type":"error","outlined":"","text":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"3"}},_vm._l((_vm.actions),function(label,action){return _c(VRow,{key:action},[_c(VBtn,{staticClass:"ma-4",on:{"click":function($event){return _vm.postSelfTest(action)}}},[_vm._v(_vm._s(label))])],1)}),1),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VCard,{staticClass:"adock-card"},[_c(VCardText,[_vm._v(_vm._s(_vm.output))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }